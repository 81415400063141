<script lang="ts">
  import logo from "$lib/assets/logo.svg"
  import logoLight from "$lib/assets/logo-light.svg"

  export let light = false;
  export let logoWithLink = true;
</script>


{#if logoWithLink}
  <a class="navbar-logo" href="/" on:click>
    {#if light}
      <img alt="24ORE Business School" src={logoLight} />
    {:else}
      <img alt="24ORE Business School" src={logo} />
    {/if}
  </a>
{:else}
  <div class="navbar-logo">
    {#if light}
      <img alt="24ORE Business School" src={logoLight} />
    {:else}
      <img alt="24ORE Business School" src={logo} />
    {/if}
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>  
  .navbar-logo {
    @apply inline-flex items-center;
  }

  .navbar-logo > img {
    @apply h-9 lg:h-[60px];
  }

  .navbar-logo > img {
    width: auto;
  }

</style>
