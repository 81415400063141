<script lang="ts">
	import classNames from 'classnames';
  // Components
  import { Grid } from '$lib/components/organisms/Layout';

  export let fixed = true
  export let fluid = false /** Set this to 'true' to extend it full width */
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<nav
  class={classNames("navbar", {
    "fixed top-0 inset-x-0": fixed
    })}
  on:click
>
  <div class="w-full border-b border-gray-100 bg-white">
    <div class="page-margin page-max-width w-full">
      <slot name="topnavbar" />
    </div>
  </div>
  <Grid class="navbar-grid h-full" noVerticalGap fluid="{fluid}">
    <div class="navbar-container">
      <slot />

      <div class="navbar-main">
        <slot name="main" />
      </div>
    </div>
  </Grid>

  <slot name="drawers" />
</nav>

<style type="text/scss" lang="scss" scoped>
  .navbar {
    @apply flex flex-col items-center;
    @apply relative;
    @apply border-b border-gray-100;
    @apply bg-white;
    @apply z-navbar;
    height: var(--navbar-height);
  }

  :global(.navbar-grid) {
    @apply bg-white z-navbar;
  }

  .navbar-container {
    @apply col-span-full row-span-full h-full;
    @apply flex items-center justify-between;
    @apply z-navbar;
  }

  .navbar-main {
    @apply self-stretch;
    @apply flex items-stretch;
  }

  :global(.navbar.fixed + *) {
    padding-top: var(--navbar-height);
  }
</style>
